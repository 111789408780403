import React, { useState } from 'react'
import { View, Text, Heading, Button, TextField, Flex} from '@aws-amplify/ui-react';
import Loadingmini from '../components/loadingmini';
import { API, Auth } from 'aws-amplify';

export const Vehicleonboard = ({fleetCode, refreshParent}) => {
  const [is_loading, updateIsLoading] = React.useState(false);
  const [displayWindow, updateDisplayWindow] = useState(false);
  const [vinValidationError, updateVinValidationError] = useState(false);
  const [createVehicleError, updateCreateVehicleError] = useState(false);
  const [showConfirmInfo, updateShowConfirmInfo] = useState(false);
  const [vin, updateVin] = useState("");
  const [make, updateMake] = useState("");
  const [model, updateModel] = useState("");
  const [year, updateYear] = useState("");
  

  async function validateVin() {
    if(vin!=="")
    {
        updateIsLoading(true)
        updateVinValidationError(false)
        updateCreateVehicleError(false)
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        var jwt = await accessToken.getJwtToken()
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt": jwt,
            "api_endpoint":"vehicleonboarding",
            "fleet_code":fleetCode,
            "command":"verify_vin",
            "vin":vin
        }};
        try {
            const res = await API.get('adminWebTools','/ago',myInit)
            //console.log('init: ' + JSON.stringify(myInit)) //temporary
            //console.log('res: ' + JSON.stringify(res)) //temporary
            updateMake(res['make']);
            updateModel(res['model']);
            updateYear(res['year']);
            if('detail' in res)
            {
              updateVinValidationError(res['detail'])
            } else {
              if(res['make']==="")
              {
                updateVinValidationError("There was an error validating your vin, are you sure it's correct?")
              } else {
                updateShowConfirmInfo(true);
              }
            }
        }
        catch (e) {
            console.log('vin verification error: '+toString(e));
            var thisError = toString(e)
            updateVinValidationError(thisError)
        }
        updateIsLoading(false)
    }
  }

  async function createVehicle() {
    if(vin!==""&&make!==""&&model!==""&&year!=="")
    {
        updateIsLoading(true)
        updateVinValidationError(false)
        updateCreateVehicleError(false)
        let session = await Auth.currentSession()      
        let accessToken = await session.getAccessToken()
        var jwt = await accessToken.getJwtToken()
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt": jwt,
            "api_endpoint":"vehicleonboarding",
            "fleet_code":fleetCode,
            "command":"create_vehicle",
            "vin":vin,
            "make":make,
            "model":model,
            "year":parseInt(year)
        }};
        console.log('init: ' + JSON.stringify(myInit)) //temporary
        try {
            const res = await API.get('adminWebTools','/ago',myInit)
            console.log('res: ' + JSON.stringify(res)) //temporary
            if('detail' in res)
            {
              updateCreateVehicleError(res['detail'])
            } else {
              updateVin("");
              updateMake("");
              updateModel("");
              updateYear("");
              updateShowConfirmInfo(false);
              refreshParent();
            }
        }
        catch (e) {
            console.log('create vehicle error: '+toString(e));
            var thisError = toString(e)
            updateVinValidationError(thisError)
        }
        updateIsLoading(false)
    }
  }

  {
    /*

          <TextField
              placeholder='License Plate Number'
              onInput={(e) => updateLicenseNumber(e.target.value)}
              defaultValue={licenseNumber}
              isRequired={true}
          ></TextField>


          <SelectField
              descriptiveText="License State"
              size="small"
              value={licenseState}
              onChange={(e) => updateLicenseState(e.target.value)}
              isRequired={true}
          >
              <option value="">-- SELECT --</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
          </SelectField>
    */
  }


  function handleUpdateVin(newValue)
  {
    updateVin(newValue);
    updateVinValidationError(false);
  }

  return (<View style={{flex:1}}>
    {
      is_loading ? <Loadingmini /> : 
      displayWindow ? 
        <View>
          <Heading level={4}>New Vehicle</Heading>
          <Text>Complete the form below to add a new vehicle to your fleet.</Text>
          <Text><i>Vehicles in Telemetry status are NOT insured.</i></Text>
          <Text><i>Period X insurance requires vehicle address.</i></Text>
          <Text><i>Period Y insurance requires vehicle address and telemetry.</i></Text>

          {
            showConfirmInfo === false ?
              <View>
              <TextField
                  placeholder='VIN'
                  onInput={(e) => handleUpdateVin(e.target.value)}
                  defaultValue={vin}
                  isRequired={true}
              ></TextField>
              <br />
              {
                vinValidationError === false ? 
                  undefined 
                :
                  <View>
                    <Heading level={4}>Please confirm</Heading>
                    <Text>There was an issue validating your VIN.</Text> 
                    <Text>Please double-check your VIN and try again.</Text>
                    <Text>If you're certain, press the button below to bypass validation.</Text>
                    <Text><b><i>{vinValidationError}</i></b></Text> 
                    <br />
                    <br />
                    <Button onClick={() => updateShowConfirmInfo(true)}>
                      I'M CERTAIN VIN IS CORRECT
                    </Button>
                  </View>
              }
              {
                vinValidationError ? undefined :
                <Button onClick={() => validateVin()}>
                    VALIDATE VIN
                </Button>
              }
              <br />
              <br />
              <Button onClick={() => updateDisplayWindow(false)}>
                  CANCEL
              </Button>
            </View>
            :
            createVehicleError ? 
              <View>
                <Heading level={4}>Error Creating Vehicle</Heading>
                <Text>{JSON.stringify(createVehicleError)}</Text>
                <br />
                <br />
                <Button onClick={() => updateShowConfirmInfo(false)}>
                    TRY AGAIN
                </Button>
              </View>
            :
              <View>
                <Heading level={4}>Please Confirm</Heading>
                <Text>VIN:</Text>
                <TextField
                    placeholder='VIN'
                    onInput={(e) => handleUpdateVin(e.target.value)}
                    defaultValue={vin}
                    isRequired={true}
                    inputProps={{ minLength: 17, maxLength:17 }}
                ></TextField>
                <br />
                <Text>Make:</Text>
                <TextField
                    placeholder='Make'
                    onInput={(e) => updateMake(e.target.value)}
                    defaultValue={make}
                    isRequired={true}
                ></TextField>
                <br />
                <Text>Model:</Text>
                <TextField
                    placeholder='Model'
                    onInput={(e) => updateModel(e.target.value)}
                    defaultValue={model}
                    isRequired={true}
                ></TextField>
                <br />
                <Text>Year:</Text>
                <TextField
                    placeholder='Year'
                    onInput={(e) => updateYear(e.target.value)}
                    defaultValue={year}
                    isRequired={true}
                    inputProps={{ minLength: 4, maxLength:4 }}
                ></TextField>
                <br />
                <Button onClick={() => createVehicle()}>
                    ADD VEHICLE
                </Button>
                <br />
                <br />
                <Button onClick={() => updateShowConfirmInfo(false)}>
                    CANCEL
                </Button>
              </View>
          }


          
        </View>
      :
        <Flex flexDirection='row' justifyContent='flex-end' alignItems='flex-end' alignContent='flex-end' gap='1em'>
          <Button onClick={() => updateDisplayWindow(true)}>
            ADD VEHICLE
          </Button>
        </Flex>
    }
  </View>);
};

export default Vehicleonboard;