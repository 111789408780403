import React, { useState } from 'react'
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import Loadingmini from '../components/loadingmini';
import { Flex, Divider, View, Text, Heading, Button} from '@aws-amplify/ui-react';


export default function Userinsurancebuckets({user, displayAdministrativeFunctions}) {
  const [is_loading, updateIsLoading] = React.useState(false);

  function formatDate(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    //var min = a.getMinutes();
    //var sec = a.getSeconds();
    var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
    var day = days[a.getDay()];
    if(hour > 12)
    {
      var thour = hour - 12
      var pmam = ' PM';
    } else {
      if(hour < 12)
      {
        if(hour < 1)
        {
          var thour = '12';
          var pmam = ' AM';
        } else {
          var thour = hour;
          var pmam = ' AM';
        }
      } else {
        var thour = '12';
        var pmam = ' PM';
      }
    }
    var time = day + ', ' + month + ' ' + date + ', ' + year + ' - ' + thour + pmam;
    return time;
  }

  return (
    is_loading ? <Loadingmini /> : 
      <Flex
        direction="column"
      >
        <View style={{padding:"1rem"}}>
          <Divider />
          {
            user ?
              user.resp ?
                user.resp[0] ?   
                  typeof(user.resp[0]["driver_insurance_data"]) === 'undefined' ?  
                  <Flex direction="column">
                      <Heading level={2}>No Insurance Data</Heading>
                      <Text>If this driver is new, it may take up to a week for new data to populate.</Text>
                      <Text>If you aren't getting your insurance through <a href='https://agorisk.com/'>Ago</a>, you could be saving a bundle.</Text>
                  </Flex>
                  :
                    user.resp[0]["driver_insurance_data"] === false || user.resp[0]["driver_insurance_data"] === null ? 
                    <Flex direction="column">
                        <Heading level={2}>No Insurance Data</Heading>
                        <Text>If this driver is new, it may take up to a week for new data to populate.</Text>
                        <Text>If you aren't getting your insurance through <a href='https://agorisk.com/'>Ago</a>, you could be saving a bundle.</Text>
                    </Flex>
                    :
                    user.resp[0]["driver_insurance_data"]["MlsTot"] === false ? //just a general check... since the line above doesn't seem to work
                    <Flex direction="column">
                        <Heading level={2}>No Insurance Data</Heading>
                        <Text>If this driver is new, it may take up to a week for new data to populate.</Text>
                        <Text>If you aren't getting your insurance through <a href='https://agorisk.com/'>Ago</a>, you could be saving a bundle.</Text>
                    </Flex>
                    :
                    <Flex direction="column">
                        <Heading level={2}>Insurance Buckets {displayAdministrativeFunctions ? "(7 days)" : "(7 days)"}</Heading>
                        <Flex wrap="wrap" gap="2rem" justifyContent="center">
                          <Flex direction="column">
                            <Divider />
                            <table><tbody>
                              {user.resp[0]["period_start"] ? 
                              <tr>
                                <th style={{paddingRight:'10px'}}>Period Start:</th>
                                <td>{formatDate(user.resp[0]["period_start"])}</td>
                              </tr>
                              : undefined}
                              {user.resp[0]["period_end"] ? 
                              <tr>
                                <th style={{paddingRight:'10px'}}>Period End:</th>
                                <td>{formatDate(user.resp[0]["period_end"])}</td>
                              </tr>
                              : undefined}
                              {user.resp[0]["driver_insurance_data"]["LiabGigInsMls"] ? 
                                <tr>
                                  <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Liability Gig Insured Miles:</th>
                                  <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{Math.round(user.resp[0]["driver_insurance_data"]["LiabGigInsMls"])}</td>
                                </tr>
                              : 
                              <tr>
                                <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Liability Gig Insured Miles:</th>
                                <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>0</td>
                              </tr>}
                              {user.resp[0]["driver_insurance_data"]["LiabGigUninsMls"] ? 
                                <tr>
                                  <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Liability Gig Uninsured Miles:</th>
                                  <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{Math.round(user.resp[0]["driver_insurance_data"]["LiabGigUninsMls"])}</td>
                                </tr>
                              : <tr>
                                  <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Liability Gig Uninsured Miles:</th>
                                  <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>0</td>
                                </tr>}
                              {user.resp[0]["driver_insurance_data"]["LiabPersMiles"] ? 
                                <tr>
                                  <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Liability Personal Miles:</th>
                                  <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{Math.round(user.resp[0]["driver_insurance_data"]["LiabPersMiles"])}</td>
                                </tr>
                              : 
                              <tr>
                                <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Liability Personal Miles:</th>
                                <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>0</td>
                              </tr>}
                              {user.resp[0]["driver_insurance_data"]["PhysDamGigInsMls"] ? 
                                <tr>
                                  <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Physical Damage Gig Insured Miles:</th>
                                  <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{Math.round(user.resp[0]["driver_insurance_data"]["PhysDamGigInsMls"])}</td>
                                </tr>
                              : 
                              <tr>
                                <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Physical Damage Gig Insured Miles:</th>
                                <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>0</td>
                              </tr>}
                              {user.resp[0]["driver_insurance_data"]["PhysDamGigUninsMls"] ? 
                                <tr>
                                  <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Physical Damage Gig Uninsured Miles:</th>
                                  <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{Math.round(user.resp[0]["driver_insurance_data"]["PhysDamGigUninsMls"])}</td>
                                </tr>
                              : 
                              <tr>
                                <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Physical Damage Gig Uninsured Miles:</th>
                                <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>0</td>
                              </tr>}
                              {user.resp[0]["driver_insurance_data"]["PhysDamPersMiles"] ? 
                                <tr>
                                  <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Physical Damage Personal Miles:</th>
                                  <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>{Math.round(user.resp[0]["driver_insurance_data"]["PhysDamPersMiles"])}</td>
                                </tr>
                              : 
                              <tr>
                                <th style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>Physical Damage Personal Miles:</th>
                                <td style={{paddinRight:'10px', paddingLeft:'10px', paddingTop:'5px', paddingBottom:'5px'}}>0</td>
                              </tr>}
                            </tbody></table>
                          </Flex>
                        </Flex>
                    </Flex>
                : 
                  <Flex direction="column">
                    <Text>Error loading user data</Text>
                    <Text>No data found for user</Text>
                  </Flex>
              : 
                <Flex direction="column">
                  <Text>Error loading user data</Text>
                  <Text>No user data</Text>
                </Flex>
            : 
              <Flex direction="column">
                <Text>Error loading user data</Text>
                <Text>User not found</Text>
              </Flex>
          }
        </View>
      </Flex>
  );
}
