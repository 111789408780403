import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { Text,Flex, View, Divider, Heading, Button} from '@aws-amplify/ui-react';
import Loadingmini from '../components/loadingmini';
import Vehiclecommands from './vehiclecommands';
import Vehiclemap from './vehiclemap';
import Vehicleinfo from './vehicleinfo';
import Vehicleinsurancedata from './vehicleinsurancedata';
//import Vehiclemaintenancehistory from './vehiclemaintenancehistory';
import Vehiclestatus from './vehiclestatus';
import Vehiclereservations from './vehiclereservations';
import Vehiclecommandhistory from './vehiclecommandhistory';
import Vehicleutilization from './vehicleutilization';
import Accidentinspector from './accidentinspector';


export default function Vehicle({paramVehicleId, paramFleet}) {
  let params = useParams();
  var tvehicleId = false
  var tfleet = false
  if(paramVehicleId)
  {
    tvehicleId = paramVehicleId;
  } else {
    tvehicleId = params.vehicleId;
  }
  if(paramFleet)
  {
    tfleet = paramFleet;
  } else {
    tfleet = params.vehicleId;
  }
  const [vehicleId, updateVehicleId] = React.useState(tvehicleId);
  const [vehicle, updateVehicle] = useState({status:"If this message stays - email us at info@joinago.com",resp:[]})
  const [is_loading, updateIsLoading] = React.useState(false);
  const [showDataPacket, updateShowDataPacket] = React.useState(false);
  const [showEngineData, updateShowEngineData] = React.useState(false);
  const [jwt, setJwt] = React.useState(false);
  const [fleet_codes, updateFleetCodes] = React.useState(null);
  const [selected_fleet, updateSelectedFleet] = React.useState(tfleet);
  const [displayAdministrativeFunctions, updateDisplayAdministrativeFunctions] = React.useState(false);
  const [displaySuperAdministrativeFunctions, updateDisplaySuperAdministrativeFunctions] = React.useState(false);
  

  const fetchVehicleData = async (tSelectedFleet) => {
    var thisFleet = ""
    if(!tSelectedFleet)
    {
        thisFleet = selected_fleet
    } else {
        if(tSelectedFleet==="")
        {
            thisFleet = selected_fleet
        } else {
            thisFleet = tSelectedFleet
        }
    }
    var thisVehicleId = vehicleId;
    if(paramVehicleId > 0)
    {
      thisVehicleId = paramVehicleId;
      updateVehicleId(thisVehicleId);
    }
    //console.log('fetching vehicle data vehicle '+thisVehicleId);
    updateIsLoading(true);
    /*
    let currentAuthUser = await Auth.currentAuthenticatedUser()
    if(currentAuthUser.signInUserSession.accessToken.payload["cognito:groups"])
    {
        if(currentAuthUser.signInUserSession.accessToken.payload["cognito:groups"][0]==='admin'||currentAuthUser.signInUserSession.accessToken.payload["cognito:groups"][0]==='support'||currentAuthUser.signInUserSession.accessToken.payload["cognito:groups"][0]==='fleet')
        {
            updateDisplayAdministrativeFunctions(true)
            if(currentAuthUser.signInUserSession.accessToken.payload["cognito:groups"][0]==='admin')
            {
              updateDisplaySuperAdministrativeFunctions(true)
            }
        }
    }
    */
    let session = await Auth.currentSession()      
    let accessToken = await session.getAccessToken()
    if(accessToken.payload["cognito:groups"])
    {
        if(accessToken.payload["cognito:groups"][0]==='admin'||accessToken.payload["cognito:groups"][0]==='support'||accessToken.payload["cognito:groups"][0]==='fleet')
        {
            updateDisplayAdministrativeFunctions(true)
            if(accessToken.payload["cognito:groups"][0]==='admin')
            {
                updateDisplaySuperAdministrativeFunctions(true)
            }
        }
    }
    var thisJwt = await accessToken.getJwtToken()
    setJwt(thisJwt)
    if(thisVehicleId > 0)
    {
      const myInit = {queryStringParameters:{ 
          back_end: process.env.REACT_APP_BACKEND, 
          jwt:thisJwt,
          api_endpoint:"vehiclesget",
          vehicle_id: thisVehicleId
      }};
      //console.log(JSON.stringify(myInit));
      try {
          const res = await API.get('adminWebTools','/ago',myInit)
          if(res.resp==false)
          {
            fetchVehicleData()
          }
          if(res.resp.length < 1)
          {
            fetchVehicleData()
          }
          //console.log(JSON.stringify(res))
          updateVehicle(res);
          updateFleetCodes(res.fleet_codes);
          updateSelectedFleet(res.fleet_code);
          updateIsLoading(false);
          /* This may be unnecessary...
          if('detail' in res)
          {
            if(res['detail'].includes("token"))
            {
              if(count < 5)
              {
                fetchVehicleData()
              }
            }
          }
          */
          //updateVehicleLoadError(res)
          return
  
      }
      catch (e) {
          //const res = {status:"Error retrieving Vehicles or User not permissioned - email us at info@joinago.com",resp:[]};
          //console.log(JSON.stringify(res))
          console.log('e:'+JSON.stringify(e))
          //updateVehicleLoadError(true)
          updateIsLoading(false)
          return
      }
    } else {
      console.log('thisVehicleId not ready, count plus one');
      fetchVehicleData()
    }
  }

  useEffect(() => {
    fetchVehicleData()
  },[params,paramVehicleId])

  
  

  return (
    is_loading ? <Loadingmini /> : 
      <Flex //  https://ui.docs.amplify.aws/react/components/flex#demo
        direction="column"
      >
        <View style={{padding:"1rem"}}>
            <Flex>
                <Heading level={1}>
                    {
                      vehicle.resp ?
                        vehicle.resp[0] ? 
                          "name" in vehicle.resp[0] ? 
                            vehicle.resp[0]["name"]!=="" ? 
                              vehicle.resp[0]["name"] 
                            : 
                              "Vehicle " + vehicleId
                          : 
                            "Vehicle " + vehicleId
                        : 
                          "Vehicle " + vehicleId
                      : 
                        "Vehicle " + vehicleId
                    }
                </Heading>
            </Flex>      
            {
              vehicle.resp ?
              vehicle.resp[0] ? 
                  "make_model_year" in vehicle.resp[0] ? 
                      <View><Heading level={4}>{vehicle.resp[0]["make_model_year"]}</Heading><br/></View>
                    : undefined 
                  : undefined
                : undefined
            }
            <Flex direction="column">
                <Flex direction="row" wrap="wrap" justifyContent="flex-start" gap="1rem">
                  <View>
                    <Button onClick={() => fetchVehicleData()}>
                        PRESS TO GET LATEST UPDATES
                    </Button>
                  </View>
                  <Button>
                      {selected_fleet}
                  </Button>
                </Flex>
            </Flex>
            <Divider />
            {
              jwt === false ? undefined :
              selected_fleet ?
                <Flex wrap="wrap" justifyContent="center" gap="1rem">
                    <Flex wrap="wrap" justifyContent="center" gap="1rem">
                      <Flex direction='column'>
                        <Vehicleinfo userJwt={jwt} vehicle={vehicle} fleetCode={selected_fleet} refreshParent={fetchVehicleData} displayAdministrativeFunctions={displayAdministrativeFunctions} displaySuperAdministrativeFunctions={displaySuperAdministrativeFunctions}/>
                        {
                          displayAdministrativeFunctions === false ? undefined:
                          <Vehiclecommands userJwt={jwt} vehicleId={vehicleId} />
                        }
                      </Flex>
                      <Vehiclemap vehicle={vehicle} />
                    </Flex>
                    {
                      displayAdministrativeFunctions === false ? undefined :
                      <View>
                        <Vehiclereservations userJwt={jwt} refreshJwt={() => fetchVehicleData()} vehicle={vehicle} />
                        <Vehiclestatus userJwt={jwt} refreshJwt={() => fetchVehicleData()} vehicle={vehicle} />
                        <Vehiclecommandhistory userJwt={jwt} refreshJwt={() => fetchVehicleData()} vehicle={vehicle} />
                      </View>
                    }
                    <Vehicleinsurancedata vehicle={vehicle} displayAdministrativeFunctions={displayAdministrativeFunctions}/>
                    {
                      vehicle.resp ?
                      vehicle.resp.length > 0 ?
                      'car_id' in vehicle.resp[0] ?
                        <Vehicleutilization userJwt={jwt} refreshJwt={() => fetchVehicleData()} paramVehicleId={vehicleId} />
                      : undefined : undefined : undefined
                    }
                    <Accidentinspector userJwt={jwt} refreshJwt={() => fetchVehicleData()} vehicle={vehicle} />
                </Flex>
              : <Text>Loading...</Text>
            }
            <Flex wrap="wrap" justifyContent="center" gap="1rem">
              {vehicle ?
                  vehicle.resp ?
                    vehicle.resp[0] ?  
                      <Flex direction="column">
                        {
                          "data" in vehicle ?
                            showEngineData === false ? <Button onClick={() => updateShowEngineData(true)}>SHOW ENGINE DATA</Button> :
                              <Flex direction="column">
                                <Heading level={2}>Engine Data</Heading>
                                <pre>
                                  {vehicle.data}
                                </pre>
                                <Button onClick={() => updateShowEngineData(false)}>HIDE ENGINE DATA</Button>
                              </Flex>
                          : undefined
                        }
                        <Divider />
                        {
                          displaySuperAdministrativeFunctions===false?undefined:
                          showDataPacket ?
                            <Flex direction="column">
                              <Heading level={2}>Data Packet</Heading>
                              <pre id="json"><p>{JSON.stringify(vehicle.resp[0],null, 4)}</p></pre>
                              <Button onClick={() => updateShowDataPacket(false)}>
                                Hide Data Packet
                              </Button>
                            </Flex>
                          : 
                            <Button onClick={() => updateShowDataPacket(true)}>
                              Vehicle Data Packet
                            </Button>
                        }
                        <br/><br/><br/><br/>
                      </Flex>
                    : undefined
                  : undefined
                : undefined}
            </Flex>
        </View>
      </Flex>
    );
}

